<template>
  <app-page-layout
    v-bind="content"
    class="page-contacts"
  >
    <template #header>
      <div class="page-contacts__address"
      >{{ $t('page.contacts.address') }}
        <br/>
        <a
          :mailto="$t('page.contacts.email')"
          class="page-contacts__email"
        >{{ $t('page.contacts.email') }}</a>
      </div>
    </template>

    <div class="page-contacts__contacts">
      <div class="page-contacts__contacts-wrapper">
        <div
          v-for="(office, index) in content.offices"
          :key="index"
          class="page-contacts__contacts-item"
        >
          <div class="page-contacts__contact">
            <div class="page-contacts__contact-region">{{ office.region }}</div>
            <div class="page-contacts__contact-phone">{{ office.phone }}</div>
          </div>
        </div>
      </div>
    </div>

    <the-contact-form />

    <!-- <the-map-contact /> -->
  </app-page-layout>
</template>

<script>
import AppPageLayout from '@/components/app-page-layout';
import TheContactForm from '@/components/the-contact-form';
// import TheMapContact from '@/components/the-map-contact';

export default {
  components: {
    AppPageLayout,
    TheContactForm,
    // TheMapContact,
  },
  computed: {
    content() {
      return this.$t('page.contacts');
    },
  },
};
</script>

<style lang="scss">
.page-contacts {

  &__address {
    white-space: pre-wrap;
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
    color: #222222;

    padding-bottom: 32px;
    min-height: 200px;
  }

  &__email {
    color: #1244F9;
  }

  &__contacts {
    @include container;
    margin-bottom: 70px;

    &-wrapper {
      display: flex;
      flex-wrap: wrap;
      margin: 0 -12px;
      justify-content: space-around;
    }

    &-item {
      flex: 0 0 auto;
      padding: 12px;
    }
  }

  &__contact {
    width: 312px;
    height: 100%;
    background: #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 8px;

    padding: 24px;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    &-region {
      font-weight: 700;
      font-size: 22px;
      line-height: 138.19%;
      text-align: center;
      color: #CA1126;

      margin-bottom: 24px;
    }

    &-phone {
      font-weight: 400;
      font-size: 22px;
      line-height: 138.19%;
      color: #000000;

      margin-bottom: 6px;
    }
  }

  @include media-bp(mob) {
    &__address {
      font-size: 16px;
      min-height: initial;
    }

    .app-page-layout__body {
      padding-top: 0;
      margin-top: -12px;
    }

  }
}
</style>
