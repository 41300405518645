<template>
  <div id="contact-form" class="the-contact-form">
    <div class="the-contact-form__container">
      <div class="the-contact-form__header">
        <i18n path="sections.contact-form.header.text">
          <template #faq>
            <router-link
              :to="{ name: 'faq' }"
            >{{ $t('sections.contact-form.header.faq') }}</router-link>
          </template>
        </i18n>
      </div>

      <app-contact-form
        class="the-contact-form__form"
        @sent="onSent"
        @error="onError"
      />
    </div>
  </div>
</template>

<script>
import AppContactForm from '@/components/app-contact-form';

export default {
  components: {
    AppContactForm,
  },
  methods: {
    onSent() {
    },
    onError(err) {
      console.error(err);
      alert('Something is wrong', err);
    },
  },
};
</script>

<style lang="scss">
.the-contact-form {
  background: #F8F7F7;
  padding-top: 28px;
  padding-bottom: 48px;

  &__container {
    @include container;
    width: 560px;
  }

  &__header {
    font-weight: 700;
    font-size: 36px;
    line-height: 138.19%;
    text-align: center;

    color: #000000;

    margin-bottom: 24px;

    white-space: pre-wrap;

    a {
      color: $red;
      text-decoration: none;
    }
  }

  @include media-bp(mob) {
    &__header {
      font-size: 20px;
      text-align: initial;
    }
  }
}
</style>
